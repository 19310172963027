import { ImgType, MarketMailType } from "@app/services/MarketMail"
import { LoanForm, LoanFormResult } from "@share/LoanForm"
import { USER_ACTION_LOG_EVENT_TYPE } from "@share/const/index"
import {
  CLIENT_FORM_COLUMNS,
  ClientForm,
} from "@share/entities/Client/ClientForm"
import { LineAction } from "@share/entities/Line/LineAction"
import { MailSubType } from "server/src/scripts/utils/recently_updated_properties_mail"
import { ClientDealType } from "./Client"
import { ClientMailRejectByType } from "./ClientMailReject"
import {
  ConsultationPreviewReservation,
  ConsultationQuestion,
  ConsultationVacancyInquiry,
} from "./Consultation"
import { ImageChart } from "./ImageChart"
import { MailTypeToClient } from "./Mail"
import { PropertyFilterCondition } from "./PropertyFilterCondition"

//
// ClientHistory
//

export type ClientHistory = {
  id: number
  createdAt: Date
  updatedAt: Date
  userId: string
  urlKey: string
  data?: {
    mailId: string
  }
} & ClientHistoryData

//
// ClientHistoryData
//

export type ClientHistoryData =
  | ({
      title: "catchUpMailSent"
    } & HasMailId &
      HistoryOfPropertiesMail)
  | ({
      title: "catchUpRentMailSent"
    } & HasMailId &
      HistoryOfPropertiesMail)
  | ({
      title: "updatedPropertiesPriceMailSent"
    } & HasMailId &
      HistoryOfPropertiesMail)
  | ({
      title: "advertiseMailSent"
      data: {
        propertyType: PropertyType
        /**
         * - buyClientGuide
         *   - 検索サイトの案内メール
         * - sellClientGuide
         *   - AI査定の案内メール
         * - LoanClientGuide
         *   - 住宅ローンの案内メール
         */
        advertiseMailType:
          | "buyClientGuide"
          | "sellClientGuide"
          | "loanClientGuide"
        dealType?: ClientDealType
      }
    } & HasMailId)
  | {
      title: "typeFormSent"
      data: {
        formId: string
        detail: {
          /**
           * 回答内容
           */
          label: string
          /**
           * 質問文
           */
          title: string
          /**
           * @deprecated 型が質問によって異なるので、回答内容は label を参照する
           */
          value: unknown
        }[]
      }
    }
  | {
      title: "questionSent"
      data: ConsultationQuestion
    }
  | {
      title: "previewReservationSent"
      data: ConsultationPreviewReservation
    }
  | {
      title: "vacancyInquirySent"
      data: ConsultationVacancyInquiry
    }
  | {
      //TODO: ClientのPartialにする
      title: "clientUpdated"
      data: {
        diffOld: Record<string, any>
        diff: Record<string, any>
      }
    }
  | {
      //TODO: ClientのPartialにする
      title: "clientUpdatedByClient"
      data: {
        diffOld: Record<string, any>
        diff: Record<string, any>
      }
    }
  | {
      title: "propertyDetailOpened"
      data: {
        propertyType: PropertyType
        propertyId: string
        location: string
      }
    }
  | {
      title: "ownerPageOpened"
      data: {
        propertyType: PropertyType
        propertyId: string
        location: string
      }
    }
  | {
      title: "userSearch"
      data: PropertyFilterCondition
    }
  | {
      title: "userMemo"
      data: {
        memo: string
        deletedAt?: IsoTime
        commentedBy?: string
      }
    }
  | ({
      title: "aiSatei"
      data: {
        propertyCondition: AiSateiPropertyCondition
        valuationResult: ValuationResult
        requestAssessment: boolean | string
        dateDayText?: string
        periodText?: string
      }
    } & HasMailId)
  | ({
      title: "clientMailSent"
      data: {
        mailType: MailTypeToClient
        subject: string
        content: string
      }
    } & HasMailId)
  | ({
      title: "hankyoAutoResponseSent"
      data: {
        dealType: ClientDealType
      }
    } & HasMailId)
  | {
      title: "userActionLog"
      data: {
        eventType: (typeof USER_ACTION_LOG_EVENT_TYPE)[keyof typeof USER_ACTION_LOG_EVENT_TYPE]
        fromUrlShareFlag: boolean
        sessionPropertySearchNum: number
        data: Record<string, any>
      }
    }
  | ({
      title: "mailOpened"
    } & HasMailId)
  | ({
      title: "mailLinkClicked"
      data: {
        type: LinkType
      }
    } & HasMailId)
  | {
      title: "hotActionAfterCount"
      data: {
        action: ClientHistoryData["title"]
        count: number
      }
    }
  | ({
      title: "marketMailSent"
      data: {
        mailType: MarketMailType
        dealType: ClientDealType
        imgType: ImgType
        nearbyUrlKeys?: string[]
        locationId: number
        saleChart: ImageChart
        contractChart: ImageChart
        lineChart: ImageChart
        barChart: ImageChart
        locationName: string
      }
    } & HasMailId)
  | {
      title: "warmActionAfterCount"
      data: {
        action: ClientHistoryData["title"]
        count: number
      }
    }
  | {
      title: "updateClientMailReject"
      data: {
        dealType: ClientDealType
        state: ClientMailRejectByType
      }
    }
  | ({
      title: "loan"
      data: {
        form: LoanForm
        formResult: LoanFormResult
        dealType: ClientDealType
      }
    } & HasMailId)
  | {
      title: "lineAction"
      data: {
        action: LineAction
      }
    }
  | {
      title: "smsSent"
      data: {
        phone: string
        message: string
      }
    }
  | {
      title: "ownerPropertiesUpdated"
      data: {
        oldPrice: number
        newPrice: number
      }
    }
  | {
      title: "sellFormSent"
      data: {
        question: string
        propertyType: string
        clientName: string
        email: string
        phone: string
        location: string
        dateDayText?: string
        periodText?: string
        source?: string
      }
    }
  | {
      title: "clientProperty"
      data: {
        resource: string
        dataType: string
        address: string
        contractPrice?: string
        price?: string
        exclusiveArea: string
        listingAgent: string
        listingAgentPhone?: string
        url?: string
        propertyId?: string
        propertyType?: string
      }
    }
  | {
      title: "mogeLinkClicked"
      data: {
        dealType: ClientDealType
        mailId?: string
      }
    }

/**
 * 用途はメールからのリンクの一覧だが、リンク自体はメールに限らないので MailLinkType ではなく LinkType とした
 * 型を置く場所はまた検討する
 */
export type LinkType =
  | "toSellForm" // フォームへの遷移
  | "toPropertyDetails" // 部屋詳細ページへの遷移
  | "toSearchResult" // 検索結果ページへの遷移
  | "toUrlShareEditForm" // 配信設定の見直しページへの遷移
  | "toUnsubscribe" // 配信拒否ページへの遷移
  | "toAiSatei" // AI査定ページ
  | "toLoan" // 住宅ローンファインダーページページ
  | "toOwner" // オーナーページ
  | "toStock" // 在庫ページ
  | "toSearchTopPage" // 検索結果ページへの遷移

/**
 * 履歴ごとの設定。追加・変更は慎重に。
 * @deprecated checkIsMailSentHistory のように関数にしたほうがよさそうなので、項目は追加しない。
 */
export const CLIENT_HISTORY_SETTINGS: {
  [_ in ClientHistoryData["title"]]: ClientHistorySettingsType
} = {
  catchUpMailSent: {
    isHotAction: false,
    isMailAction: true,
  },
  updatedPropertiesPriceMailSent: {
    isHotAction: false,
    isMailAction: true,
  },
  catchUpRentMailSent: {
    isHotAction: false,
    isMailAction: true,
  },
  advertiseMailSent: {
    isHotAction: false,
    isMailAction: true,
  },
  typeFormSent: {
    isHotAction: true,
  },
  questionSent: {
    isHotAction: true,
  },
  previewReservationSent: {
    isHotAction: true,
  },
  vacancyInquirySent: {
    isHotAction: true,
  },
  clientUpdated: {
    isHotAction: false,
    isUpdateAction: true,
  },
  clientUpdatedByClient: {
    isHotAction: false,
    isUpdateAction: true,
  },
  propertyDetailOpened: {
    isHotAction: false, // 有望顧客が多くなりすぎて邪魔なので false に変更
    isSiteAction: true,
  },
  ownerPageOpened: {
    isHotAction: false,
    isSiteAction: true,
  },
  userMemo: {
    isHotAction: false,
  },
  aiSatei: {
    isHotAction: true,
  },
  loan: {
    isHotAction: true,
  },
  clientMailSent: {
    isHotAction: false,
    isMailAction: true,
  },
  userActionLog: {
    isHotAction: false,
    isSiteAction: true,
  },
  userSearch: {
    isHotAction: false,
    isSiteAction: true,
  },
  mailOpened: {
    isHotAction: false,
  },
  mailLinkClicked: {
    isHotAction: false,
  },
  hankyoAutoResponseSent: {
    isHotAction: false,
    isMailAction: true,
  },
  // This history type was classified as hot action before,
  // due to requirement changes it became false, but the name still remains...
  hotActionAfterCount: {
    isHotAction: false,
  },
  marketMailSent: {
    isHotAction: false,
    isMailAction: true,
  },
  warmActionAfterCount: {
    isHotAction: false,
  },
  updateClientMailReject: {
    isHotAction: false,
    isUpdateAction: true,
  },
  lineAction: {
    isHotAction: true,
    isUpdateAction: false,
  },
  smsSent: {
    isHotAction: false,
    isMailAction: true,
  },
  ownerPropertiesUpdated: {
    isHotAction: false,
    isSiteAction: true,
  },
  sellFormSent: {
    isHotAction: true,
  },
  clientProperty: {
    isHotAction: true,
  },
} as const

type ClientHistorySettingsType = {
  /**
   * 顧客からの有望なアクションかどうか。
   *
   * この履歴が保存された直後、顧客の latest_hot_action_datetime が更新される。
   */
  isHotAction: boolean
  isSiteAction?: boolean
  isMailAction?: boolean
  isUpdateAction?: boolean
}

/**
 * メール関連の履歴
 */
type HasMailId = {
  data: {
    mailId: string
    fromAddress?: string
    mailSubType?: MailSubType
  }
}
/**
 * メール送信系の履歴かどうかをチェックする
 * @param history 検証したい履歴
 */
export function checkHasMailId(
  history: ClientHistoryData
): history is ClientHistoryData & HasMailId {
  return Object.hasOwn(history.data, "mailId")
}

/**
 * 顧客への配信メールの送信履歴
 */
type HistoryOfPropertiesMail = {
  data: {
    propertyType: PropertyType
    sentDetails: {
      propertyId: string
      location: string
      propertyType?: string
      isLand?: boolean
    }[]
  }
}

export function checkIsHistoryOfPropertiesMail(
  history: ClientHistoryData
): history is ClientHistoryData & HistoryOfPropertiesMail {
  return ["propertyType", "sentDetails"].every((property) =>
    Object.hasOwn(history.data, property)
  )
}

export function checkIsHistoryOfProperty(
  history: ClientHistoryData
): history is ClientHistoryData & {
  data: { propertyId: string }
} {
  return Object.hasOwn(history.data, "propertyId")
}

/**
 * メールの送信履歴のうち、メールの開封状況等の履歴が存在するものであるかどうか
 *
 * - メールタイプが以下のいずれか
 *   - 物件メール(物件提案新着、査定更新提案)
 *   - テキストメール
 * - mailId が存在しない古いメール送信履歴は、開封状況の履歴が存在しないので除外する。
 */
export function hasMailOpenState(history: ClientHistoryData) {
  const isPropertiesMail = checkIsHistoryOfPropertiesMail(history)
  const isTextMail = history.title === "clientMailSent"
  const isAdvertiseMail = history.title === "advertiseMailSent"
  const isHankyoAutoResponseMail = history.title === "hankyoAutoResponseSent"
  const isMarketMail = history.title === "marketMailSent"
  const needFooterMailOpened =
    isPropertiesMail ||
    isTextMail ||
    isAdvertiseMail ||
    isHankyoAutoResponseMail ||
    isMarketMail
  const newEnough = checkHasMailId(history)

  return needFooterMailOpened && newEnough
}

export function getPropertyIdsFromHistory(history: ClientHistoryData) {
  if (
    history.title === "catchUpMailSent" ||
    history.title === "catchUpRentMailSent" ||
    history.title === "updatedPropertiesPriceMailSent"
  ) {
    return history.data.sentDetails.map((p) => p.propertyId)
  }
  if (
    history.title === "questionSent" ||
    history.title === "previewReservationSent" ||
    history.title === "vacancyInquirySent" ||
    history.title === "propertyDetailOpened" ||
    history.title === "ownerPageOpened"
  ) {
    return [history.data.propertyId]
  }
}

/**
 * 履歴が latest_interaction_datetime を更新するためだけのものかどうか。
 * つまり、顧客対応を済ませたことを示す履歴か、本来するべき顧客対応をスキップした履歴。
 */
export function getIsHistoryUpdateLatestInteractionDatetime(
  history: ClientHistoryData
) {
  if (history.title !== "clientUpdated") {
    return false
  }
  // FIXME: 古い clientUpdated history には diff が存在しない。
  // 古いケースを含めた型にするか、古いデータに diff/oldDiff を加える。
  if (!history.data.diff?.latestInteractionDatetime) {
    return false
  }
  // @share/entities/client の @share への移行が難しく、
  // history.data.diff を Client の Partial にしにくいので as で解消している
  const keys = Object.keys(history.data.diff) as (keyof ClientForm)[]
  return !keys.some((key) => CLIENT_FORM_COLUMNS.includes(key))
}

/**
 * メール送信履歴への対応データ（メール開封履歴とメールリンククリック履歴 `MailInteractionData`）をとりだすゲッターを作る。
 *
 * @example
 * ```
 * const getMailInteractionChecker = createGetMailInteractionChecker(histories)
 * const data = getMailInteractionData(history)
 * data?.opened // true | undefined
 * data?.clickedLink.toSellForm // true | undefined
 * ```
 */
export function createGetMailInteractionChecker(
  histories: ClientHistoryData[]
) {
  const map: { [_ in string]: MailInteractionData } =
    createGetMailInteractionMap(histories)
  return (history: ClientHistoryData) => {
    if (checkHasMailId(history)) {
      return map[history.data.mailId]
    }
    return undefined
  }
}

export function createGetMailInteractionMap(histories: ClientHistoryData[]) {
  const map: { [_ in string]: MailInteractionData } = {}
  for (const history of histories) {
    if (history.title === "mailOpened" || history.title === "mailLinkClicked") {
      // 初期化
      if (!map[history.data.mailId]) {
        map[history.data.mailId] = {
          opened: false,
          clickedLinks: [],
        }
      }
      // 開封
      if (history.title === "mailOpened") {
        map[history.data.mailId].opened = true
      }
      // リンククリック
      if (history.title === "mailLinkClicked") {
        map[history.data.mailId].clickedLinks.push(history.data.type)
      }
    }
  }
  return map
}

export type MailInteractionData = {
  opened: boolean
  clickedLinks: LinkType[]
}
