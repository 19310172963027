import { dotNotations } from "@share/entities/filterOptions"
import { cloneDeep, get, isEmpty, reduce, set, times } from "lodash"
import { initialFilter } from "./storeInitialData"
const fiveToHundred = times(100, String).slice(5)

// priority は省略すると 0
const filterConfig = {
  locationName: { type: "textList" },
  contractDate: {
    type: "custom",
    handler: (contractDate) => {
      if (!contractDate || contractDate === "all") {
        return null
      }
      const current = new Date().getTime()
      return (property) => {
        if (property.dataType !== "contract") {
          return true
        }
        if (!property.contractDate) {
          return false
        }
        const contract = new Date(property.contractDate).getTime()
        const diffTime = current - contract
        const diffYear = Math.floor(diffTime / 1000 / 60 / 60 / 24 / 365)
        return diffYear <= contractDate
      }
    },
  },
  saleUpdateDate: {
    type: "custom",
    handler: (saleUpdateDate) => {
      if (!saleUpdateDate || saleUpdateDate === "all") {
        return null
      }
      const current = new Date().getTime()
      return (property) => {
        if (property.dataType !== "sale") {
          // sale property 以外には無関係
          return true
        }
        if (!property.updatedAt) {
          return false
        }
        const updatedAt = new Date(property.updatedAt).getTime()
        const diffTime = current - updatedAt
        const diffDay = Math.floor(diffTime / 1000 / 60 / 60 / 24)
        return diffDay <= saleUpdateDate
      }
    },
  },
  rentUpdateDate: {
    type: "custom",
    handler: (rentUpdateDate) => {
      if (!rentUpdateDate) {
        return null
      }
      const current = new Date().getTime()
      return (property) => {
        if (property.dataType !== "rent") {
          // rent property 以外には無関係
          return true
        }
        if (!property.updatedAt) {
          return false
        }
        const updatedAt = new Date(property.updatedAt).getTime()
        const diffTime = current - updatedAt
        const diffDay = Math.floor(diffTime / 1000 / 60 / 60 / 24)
        return diffDay <= rentUpdateDate
      }
    },
  },
  contractPrice: { type: "numberRange" },
  rentPrice: { type: "numberRange" },
  contractUnitPrice: { type: "numberRange" },
  exclusiveArea: { type: "numberRange" },
  buildingArea: { type: "numberRange" },
  landArea: { type: "numberRange" },
  floor: { type: "numberRange" },
  floorPlanNumberOfRooms: {
    type: "custom",
    handler: (checkboxes) => {
      let _checkboxes = checkboxes
      if (isEmpty(checkboxes)) {
        return null
      }
      const idx5orMore = _checkboxes.indexOf("5orMore")
      if (idx5orMore !== -1) {
        _checkboxes = checkboxes.concat(fiveToHundred)
      }
      const filterFactory = filterLogics.checkbox(_checkboxes)
      return filterFactory
    },
  },
  aboveGroundFloorLevels: { type: "numberRange" },
  constructionYearmonth: { type: "yearRange" },
  ownerChange: {
    type: "custom",
    handler: (oc) => {
      if (oc === "all") {
        return null
      }
      return (property) => {
        return (
          (oc === "ownerChange" && property.ownerChange) ||
          (oc === "notOwnerChange" && !property.ownerChange)
        )
      }
    },
  },
}

// フィルタリングの必要がなければ null を返す
// そうでなければ (property, attrName) を受け取って boolean を返す関数を返す
const filterLogics = {
  text: (text) => {
    if (!text) {
      return null
    }
    const regex = new RegExp(text)
    return (property, attrName) => {
      return regex.test(property[attrName])
    }
  },
  textList: (textList) => {
    if (isEmpty(textList)) {
      return null
    }
    const filters = textList.map((text) => filterLogics.text(text))
    return (property, attrName) => {
      return filters.some((filter) => filter(property, attrName))
    }
  },
  checkbox: (arr) => {
    if (isEmpty(arr)) {
      return null
    }
    return (property, attrName) => {
      return arr.some(
        (text) => property[attrName] && text === property[attrName].toString()
      )
    }
  },
  yearRange: ({ from, to }) => {
    const fromYear = !from ? 1900 : new Date(from.toString()).getFullYear()
    const toYear = !to ? 3000 : new Date(to.toString()).getFullYear()

    return (property, attrName) => {
      const targetValue =
        property[attrName] &&
        property[attrName].split &&
        property[attrName].split("/")[0]
      if (!targetValue && !from && !to) {
        return true
      }
      if (isNaN(targetValue)) {
        return true
      }
      return fromYear <= targetValue && targetValue <= toYear
    }
  },
  numberRange: ({ from = 0, to = 99999999999 }) => {
    if (from === "0" && to === "99999999999") {
      return null
    }
    const fromNum = parseInt(from)
    const toNum = parseInt(to)
    return (property, attrName) => {
      const targetValue = property[attrName]
      return fromNum <= targetValue && targetValue <= toNum
    }
  },
}

/**
 * 物件情報をfilterに基づいてフィルターをかけ、その途中経過とともに返す
 * @param {Property[]} properties
 * @param {Filter} filter
 * @returns {{filterDetails: string[], properties: Property[]}}
 */
export const filterFunc = (properties, filter) => {
  const filters = []
  const filterDetails = []

  for (const attrName in filter) {
    const config = filterConfig[attrName]
    if (!config) {
      continue
    }
    const { type, priority, handler } = config
    const funcFuctory = type === "custom" ? handler : filterLogics[type]
    const filterFunc = funcFuctory(filter[attrName])
    if (filterFunc === null) {
      // すべての物件が条件を満たすのでフィルタリングの必要なし
      continue
    }
    filters.push({ filterFunc, attrName, priority: priority || 0 })
  }

  filters.sort((lhs, rhs) => {
    lhs.priority - rhs.priority
  })
  filterDetails.push(["before", properties.length])
  const filtered = filters.reduce((properties, { filterFunc, attrName }) => {
    const result = properties.filter((property) =>
      filterFunc(property, attrName)
    )
    filterDetails.push([attrName, result.length])
    return result
  }, properties)
  return {
    properties: filtered,
    filterDetails,
  }
}

/**
 * Filterを分解してFilterFlatAttributesにする
 * @param {Filter} filter
 * @returns {import("@share/entities/Client").FilterFlatAttributes}
 */
export const toFlatAttributes = (filter) => {
  return reduce(
    dotNotations,
    (result, dotNotation, name) => {
      const value = get(filter, dotNotation)
      if (value !== undefined) {
        result[name] = value
      }
      return result
    },
    {}
  )
}

/**
 * FilterFlatAttributesからFilterを組み立てる。
 * 歯抜けになっている部分はデフォルト値で埋まっているものを作る
 * @param {import("@share/entities/Client").FilterFlatAttributes} flatAttributes
 * @returns {Filter}
 */
export const flatAttributesToFilter = (flatAttributes) => {
  return reduce(
    dotNotations,
    (result, dotNotation, key) => {
      const value = flatAttributes[key]
      if (value) {
        set(result, dotNotation, value)
      }
      return result
    },
    cloneDeep(initialFilter)
  )
}

export const sanitizeFilterValues = (filter, { propertyType }) => {
  // propertyType に応じて、不要なフィルター項目をデフォルト値で上書きする必要がある
  // NOTE: ここでパッチを当てるのではなく、うまいこと構造的に解決したい マジックナンバーもよくない
  const resetFiltersByPropertyType = {
    apartment: {
      landArea: {
        from: "0",
        to: "99999999999",
      },
    },
    house: {
      floor: {
        from: "0",
        to: "99999999999",
      },
    },
    land: {
      floor: {
        from: "0",
        to: "99999999999",
      },
    },
    houseLand: {
      floor: {
        from: "0",
        to: "99999999999",
      },
    },
  }
  const resetFilters = resetFiltersByPropertyType[propertyType]
  if (!resetFilters) {
    throw new Error("unknown propertyType " + propertyType)
  }
  Object.assign(filter, resetFilters)
  return filter
}
